import { Ticket } from '@hpx-it/queue-client';
import { Button } from '@mui/material';
import { useState } from 'react';

import { getUnavailableButtonStyle } from './style';
import { UnavailableDialog } from './UnavailableDialog';

type UnavailableButtonProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const UnavailableButton = ({
  ticket,
  setTicket,
}: UnavailableButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        {...getUnavailableButtonStyle()}
        onClick={() => setDialogOpen(true)}
      >
        I'm unavailable for a video call right now.
      </Button>
      <UnavailableDialog
        ticket={ticket}
        setTicket={setTicket}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </>
  );
};
