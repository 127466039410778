import { Paper } from '@mui/material';
import { ComponentProps } from 'react';

export const getTicketPaperStyle = (): ComponentProps<typeof Paper> => ({
  elevation: 4,
  sx: {
    textAlign: 'center',
    my: 3,
    p: 3,
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0.5rem',
    opacity: 1,
    pointerEvents: 'initial',
  },
});
