import { queue } from '@hpx-it/queue-client';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { getDividerStyle } from '../../style';
import {
  getTimeslotDialogListBoxStyle,
  getTimeslotDialogListStyle,
} from './style';
import { TimeslotDialogListItem } from './TimeslotDialogListItem';

type TimeslotDialogListProps = {
  setTimeSelectOpen: (open: boolean) => void;
  setSelectedTimeslot: (timeslot: queue.AvailableTimeslot) => void;
  allowedTimes: queue.AvailableTimeslot[];
  formatTimeslot: (timeslot: queue.AvailableTimeslot) => string;
};

export const TimeslotDialogList = ({
  setTimeSelectOpen,
  setSelectedTimeslot,
  allowedTimes,
  formatTimeslot,
}: TimeslotDialogListProps) => {
  return (
    <Box {...getTimeslotDialogListBoxStyle()}>
      <List {...getTimeslotDialogListStyle()} sx={{}}>
        {allowedTimes.map((timeslot) => (
          <>
            <TimeslotDialogListItem
              setTimeSelectOpen={setTimeSelectOpen}
              setSelectedTimeslot={setSelectedTimeslot}
              timeslot={timeslot}
              formatTimeslot={formatTimeslot}
            />
            <Divider {...getDividerStyle()} />
          </>
        ))}
      </List>
    </Box>
  );
};
