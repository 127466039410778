import { Ticket } from '@hpx-it/queue-client';
import { Button } from '@mui/material';
import { QueueContext } from 'contexts';
import { useLoadingButton } from 'hooks';
import { useContext } from 'react';

import { getJoinCallButtonStyle } from './style';

type JoinCallButtonProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const JoinCallButton = ({ ticket, setTicket }: JoinCallButtonProps) => {
  const { setRouteToVideoRoom } = useContext(QueueContext);
  const loadingButtonProps = useLoadingButton(async () => {
    if (ticket.isOnDemand()) {
      setTicket(await ticket.on('ACKNOWLEDGE'));
    }
    setRouteToVideoRoom(true);
  });

  return (
    <Button {...getJoinCallButtonStyle()} {...loadingButtonProps}>
      Join Call
    </Button>
  );
};
