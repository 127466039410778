import { Button } from '@mui/material';
import { ComponentProps, useState } from 'react';

export const useLoadingButton = (onClickFn: () => void) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await onClickFn();
    } finally {
      setLoading(false);
    }
  };

  const props: ComponentProps<typeof Button> = {
    onClick: handleClick,
    disabled: loading,
  };

  return props;
};
