import { Typography } from '@mui/material';
import { useState } from 'react';

import { CancelRemoteAssistDialog } from './CancelRemoteAssistDialog';
import { getCancelHereStyle, getFooterSentenceStyle } from './style';

export const CancelRemoteAssist = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Typography {...getFooterSentenceStyle()} component={'span'}>
        To cancel your video appointment,{' '}
        <Typography
          onClick={() => setDialogOpen(true)}
          {...getCancelHereStyle()}
        >
          click here
        </Typography>
        .
      </Typography>
      <CancelRemoteAssistDialog open={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
};
