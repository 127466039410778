import { Ticket } from '@hpx-it/queue-client';
import { Button } from '@mui/material';
import { useLoadingButton } from 'hooks';

import { getAcknowledgeButtonStyle } from './style';

type AcknowledgeButtonProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const AcknowledgeButton = ({
  ticket,
  setTicket,
}: AcknowledgeButtonProps) => {
  const loadingButtonProps = useLoadingButton(async () =>
    setTicket(await ticket.on('ACKNOWLEDGE')),
  );

  return (
    <Button {...getAcknowledgeButtonStyle()} {...loadingButtonProps}>
      I'm home and available for a video call.
    </Button>
  );
};
