import { Box, Divider, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getTimeslotDividerStyle = (): ComponentProps<typeof Divider> => ({
  variant: 'middle',
  sx: {
    py: '0.2rem',
    width: '75%',
  },
});

export const getDateTimeTitleStyle = (): ComponentProps<typeof Typography> => ({
  color: 'grey',
});

export const getCenteredBoxStyle = (): ComponentProps<typeof Box> => ({
  display: 'flex',
  justifyContent: 'center',
});

export const getBottomDividerBoxStyle = (): ComponentProps<typeof Box> => ({
  display: 'flex',
  justifyContent: 'center',
  mb: 1,
});
