import { Box, Divider, Grid, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getTicketScreenBoxStyle: () => ComponentProps<
  typeof Box
> = () => ({
  textAlign: 'center',
  height: 'calc(100dvh - 50px)',
  mt: 3,
  width: '80vw',
  maxWidth: '400px',
});

export const getScreenTitleStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  mb: 1,
  fontWeight: 500,
  fontSize: '1.1rem',
});

export const getReferenceStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  mb: 2,
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  orientation: 'horizontal',
  sx: {
    my: 2,
  },
});

export const getLoadingBoxStyle: (
  windowHeight: number,
) => ComponentProps<typeof Grid> = (windowHeight) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: `${windowHeight - 50}px`, // subtracting the height of the TopBar, adjust accordingly
});
