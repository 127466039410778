import { Ticket } from '@hpx-it/queue-client';
import { Button } from '@mui/material';
import { useLoadingButton } from 'hooks';

import { getUnavailableButtonStyle } from './style';

type AvailableButtonProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const AvailableButton = ({
  ticket,
  setTicket,
}: AvailableButtonProps) => {
  const loadingButtonProps = useLoadingButton(async () =>
    setTicket(await ticket.on('RESIDENT_AVAILABLE')),
  );

  return (
    <Button {...getUnavailableButtonStyle()} {...loadingButtonProps}>
      I'm available for my call again.
    </Button>
  );
};
