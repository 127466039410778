import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { QueueContext, RemoteAssistContext } from 'contexts';
import { useContext, useState } from 'react';
import { CancelRemoteAssist } from 'screens/TicketScreen/TicketScreenFooter/CancelRemoteAssist';

import {
  termsAndConditionsBoxStyling,
  termsAndConditionsButtonStyling,
  termsAndConditionsContainerStyling,
  termsAndConditionsStackStyling,
  termsAndConditionsTextStyling,
  termsAndConditionsTitleStyling,
  termsAndConnditionsListStyling,
} from './styles';

export const TermsAndConditions = () => {
  const { setTermsAccepted } = useContext(RemoteAssistContext);
  const { ticket, setTicket } = useContext(QueueContext);
  const [toggled, setToggled] = useState(false);

  const toggleTermsAccepted = () => {
    setToggled((prevAcceptedState) => !prevAcceptedState);
  };

  const handleContinueClick = async () => {
    setTermsAccepted(true);
    if (ticket) {
      setTicket(await ticket.on('ACCEPT_TERMS'));
    }
  };

  return (
    <Container maxWidth="lg" {...termsAndConditionsContainerStyling()}>
      <Box {...termsAndConditionsBoxStyling()}>
        <Typography {...termsAndConditionsTitleStyling}>
          Remote Visit Terms
        </Typography>

        <br />

        <Typography {...termsAndConditionsTextStyling}>
          By checking the box below and continuing, I agree to receive recurring
          text messages from Pathlight at the number provided regarding my
          requests for remote repair and maintenance services, including to
          schedule services and receive a link to start a video call. Msg and
          data rates may apply. STOP to stop, HELP for help. I agree that by
          participating in a video call with Pathlight Property Management or
          its service providers or agents (“ Pathlight ”) and turning on my
          microphone and/or camera (“ Remote Visit ”), I consent and agree to
          Pathlight, its affiliates, and its agents (including third party video
          technology providers) photographing, recording, and/or filming and
          storing audio and video footage, including my voice and likeness, and
          any information I provide, to assist with my repair and maintenance
          request and to review and analyze the effectiveness of Pathlight’s
          remote maintenance services, in accordance with{' '}
          <Link href="https://www.pathlightmgt.com/privacy">
            Pathlight’s Privacy Policy
          </Link>
          .
        </Typography>

        <br />

        <Typography {...termsAndConnditionsListStyling}>
          In addition, I agree:
        </Typography>

        <Typography {...termsAndConnditionsListStyling}>
          1. To be aware of my surroundings when engaging with Pathlight and
          using my camera and microphone during the Remote Visit.
        </Typography>

        <br />

        <Typography {...termsAndConnditionsListStyling}>
          2. To appear alone in the video call, or if the voice or likeness of
          any other individuals appear during the Remote Visit, to obtain their
          consent to appear in the Remote Visit.
        </Typography>

        <br />

        <Typography {...termsAndConnditionsListStyling}>
          3. If any other individuals are present or appear in the Remote Visit,
          that I have authority to provide consent on their behalf.
        </Typography>

        <br />

        <Typography {...termsAndConnditionsListStyling}>
          I further agree that Pathlight may use any feedback, suggestions,
          critiques, ideas, or other information provided during the Remote
          Visit in accordance with its{' '}
          <Link href="https://www.pathlightmgt.com/terms_of_use">
            Terms of Use
          </Link>
          .
        </Typography>
      </Box>
      <Stack {...termsAndConditionsStackStyling}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox value={toggled} onClick={toggleTermsAccepted} />}
            required
            label="I agree to the Remote Visit Terms"
          />
        </FormGroup>
        <Button
          {...termsAndConditionsButtonStyling}
          disabled={!toggled}
          onClick={handleContinueClick}
        >
          Continue
        </Button>
        <Box pt={1}>
          <CancelRemoteAssist />
        </Box>
      </Stack>
    </Container>
  );
};
