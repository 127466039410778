import { Ticket } from '@hpx-it/queue-client';
import { Divider, Grid } from '@mui/material';

import { AcknowledgeButton } from './AcknowledgeButton';
import { AvailableButton } from './AvailableButton';
import { ExtendButton } from './ExtendButton';
import { JoinCallButton } from './JoinCallButton';
import { RescheduleButton } from './RescheduleButton';
import { getDividerStyle, getTicketActionsGridStyle } from './style';
import { UnavailableButton } from './UnavailableButton';

type TicketActionsProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

type TicketActionsStrategy = {
  accept: (ticket: Ticket) => boolean;
  handle: (ticket: Ticket, setTicket: (ticket: Ticket) => void) => JSX.Element;
};

const ON_DEMAND_TICKET_ACTIONS_STRATEGIES: TicketActionsStrategy[] = [
  {
    accept: (ticket) =>
      ticket.isWithinTimeslot() && ticket.isResidentUnavailable(),
    handle: (ticket, setTicket) => (
      <AvailableButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: (ticket) => ticket.isRemoteAssistImminent(),
    handle: (ticket, setTicket) => (
      <JoinCallButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: () => true,
    handle: (ticket, setTicket) => (
      <RescheduleButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: (ticket) =>
      ticket.isAcknowledged() &&
      ticket.isWithinTimeslot() &&
      !ticket.isResidentUnavailable(),
    handle: (ticket, setTicket) => (
      <UnavailableButton ticket={ticket} setTicket={setTicket} />
    ),
  },
];

const SCHEDULED_TICKET_ACTIONS_STRATEGIES: TicketActionsStrategy[] = [
  {
    accept: (ticket) =>
      ticket.isWithinTimeslot() &&
      ticket.isResidentUnavailable() &&
      !ticket.hasTimeslotEnded(),
    handle: (ticket, setTicket) => (
      <AvailableButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: (ticket) =>
      !ticket.isAcknowledged() &&
      (ticket.is('ABLE_TO_ACKNOWLEDGE') ?? false) &&
      !ticket.isResidentUnavailable() &&
      !ticket.hasTimeslotEnded(),
    handle: (ticket, setTicket) => (
      <AcknowledgeButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: (ticket) =>
      ticket.isAcknowledged() &&
      ticket.isRemoteAssistImminent() &&
      !ticket.isResidentUnavailable() &&
      !ticket.hasTimeslotEnded(),
    handle: (ticket, setTicket) => (
      <JoinCallButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: () => true,
    handle: (ticket, setTicket) => (
      <RescheduleButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: (ticket) =>
      ticket.isAcknowledged() &&
      ticket.isWithinTimeslot() &&
      !ticket.isResidentUnavailable() &&
      !ticket.hasTimeslotEnded(),
    handle: (ticket, setTicket) => (
      <UnavailableButton ticket={ticket} setTicket={setTicket} />
    ),
  },
  {
    accept: (ticket) =>
      ticket.isAcknowledged() &&
      (ticket.is('ABLE_TO_EXTEND') ?? false) &&
      !ticket.isResidentUnavailable() &&
      !ticket.hasTimeslotEnded(),
    handle: (ticket, setTicket) => (
      <ExtendButton ticket={ticket} setTicket={setTicket} />
    ),
  },
];

export const TicketActions = ({ ticket, setTicket }: TicketActionsProps) => {
  return (
    <>
      <Divider {...getDividerStyle()} />
      <Grid item container {...getTicketActionsGridStyle()}>
        {ticket.isOnDemand() ? (
          <>
            {ON_DEMAND_TICKET_ACTIONS_STRATEGIES.filter((strategy) =>
              strategy.accept(ticket),
            ).map((strategy) => (
              <Grid item>{strategy.handle(ticket, setTicket)}</Grid>
            ))}
          </>
        ) : (
          <>
            {SCHEDULED_TICKET_ACTIONS_STRATEGIES.filter((strategy) =>
              strategy.accept(ticket),
            ).map((strategy) => (
              <Grid item>{strategy.handle(ticket, setTicket)}</Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};
