import { Box, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getTimeslotTitleStyle = (): ComponentProps<typeof Typography> => ({
  fontWeight: 500,
  mb: 0.5,
});

export const getTimeslotDateStyle = (): ComponentProps<typeof Typography> => ({
  color: 'grey',
});

export const getTimeRangeBoxStyle = (): ComponentProps<typeof Box> => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const getTimeslotTimeStyle = (): ComponentProps<typeof Typography> => ({
  color: 'grey',
});
