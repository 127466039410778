import {
  MakeRequestResponse,
  RequestOptions,
  makeRequest as defaultMakeRequest,
} from '@hpx-it/react-app';
import { ReactNode, createContext, useCallback, useMemo } from 'react';

type MakeRequestContextProps = {
  makeRequest: typeof defaultMakeRequest;
  token?: string;
};

type MakeRequestProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: MakeRequestContextProps = {
  makeRequest: async () => ({}),
};

export const MakeRequestContext =
  createContext<MakeRequestContextProps>(DEFAULT_CONTEXT);

export const MakeRequestProvider = ({ children }: MakeRequestProviderProps) => {
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const token = useMemo(
    () => searchParams.get('token') ?? undefined,
    [searchParams],
  );

  const makeRequest = useCallback(
    async function makeRequest<T>(
      name: string,
      path: string,
      context?: RequestOptions,
    ): Promise<MakeRequestResponse<T>> {
      return await defaultMakeRequest<T>(
        name,
        `${process.env.REACT_APP_DEVELOPER_API_URL}${path}`,
        {
          ...context,
          headers: {
            ...context?.headers,
            ...(token && { authorization: `Bearer ${token}` }),
          },
        },
      );
    },
    [token],
  );

  return (
    <MakeRequestContext.Provider value={{ makeRequest, token }}>
      {children}
    </MakeRequestContext.Provider>
  );
};
