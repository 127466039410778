import { queue } from '@hpx-it/queue-client';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { CalendarDialog } from './CalendarDialog';
import { DateButton } from './DateButton';

export type SelectDateProps = {
  allowedDays: number[];
  selectedDate: DateTime;
  setSelectedDate: (date: DateTime) => void;
  dateFormat: string;
  availableTimeslots: queue.AvailableTimeslot[];
  setFilteredTimeslots: (timeslots: queue.AvailableTimeslot[]) => void;
};

export const SelectDate = ({
  allowedDays,
  selectedDate,
  setSelectedDate,
  dateFormat,
}: SelectDateProps) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const shouldDisableDate = (date: DateTime) => {
    return !allowedDays.includes(date.startOf('day').toMillis());
  };

  const onCalendarSelect = (date: DateTime) => {
    setCalendarOpen(false);
    setSelectedDate(date);
  };

  return (
    <>
      <DateButton
        selectedDate={selectedDate}
        setCalendarOpen={setCalendarOpen}
        dateFormat={dateFormat}
      />
      <CalendarDialog
        calendarOpen={calendarOpen}
        setCalendarOpen={setCalendarOpen}
        selectedDate={selectedDate}
        shouldDisableDate={shouldDisableDate}
        onCalendarSelect={onCalendarSelect}
      />
    </>
  );
};
