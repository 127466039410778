import { DeveloperApi } from '@hpx-it/developer-api-types';
import { logger } from '@hpx-it/react-app';
import { Theme } from '@mui/material';
import jwtDecode from 'jwt-decode';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { generateTheme } from 'theme';

import { MakeRequestContext } from '../makeRequestContext';

type ClientBranding = {
  primaryColor?: string;
  logoURL?: string;
};

type ClientContextProps = {
  loading: boolean;
  branding: ClientBranding;
};

export type ClientProviderProps = {
  setTheme: Dispatch<SetStateAction<Theme>>;
  children: ReactNode;
};

const DEFAULT_CONTEXT: ClientContextProps = {
  loading: true,
  branding: {},
};

export const ClientContext = createContext<ClientContextProps>(DEFAULT_CONTEXT);

export const ClientProvider = ({ setTheme, children }: ClientProviderProps) => {
  const { makeRequest, token } = useContext(MakeRequestContext);
  const [branding, setBranding] = useState<ClientBranding>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (branding.primaryColor) {
      setTheme(generateTheme(branding.primaryColor));
    }
  }, [branding.primaryColor, setTheme]);

  useMemo(async () => {
    if (!token) {
      logger.warn('No token found');
      setLoading(false);
      return '';
    }
    try {
      const { client_id }: { client_id: string } = jwtDecode(token);
      const { data, error } =
        await makeRequest<DeveloperApi.Clients.BaseClient>(
          'getClientWithSignedLink',
          '/client/session/client',
        );

      if (error) {
        logger.error(`Failed to get client ${client_id}`);
        return;
      }

      if (data?.branding) {
        const branding = new DeveloperApi.Clients.Branding(data.branding);
        setBranding((prevBranding) => {
          return {
            ...prevBranding,
            primaryColor: branding.getPaletteColor('primary'),
          };
        });

        const logoAttachmentId = branding.getAttachmentId('logo');
        if (logoAttachmentId) {
          const { data: logo } = await makeRequest<Blob>(
            'downloadAttachmentWithSignedLink',
            `/attachments/session/${logoAttachmentId}/download`,
            { responseType: 'blob' },
          );
          if (logo) {
            setBranding((prevBranding) => {
              return {
                ...prevBranding,
                logoURL: URL.createObjectURL(logo),
              };
            });
          }
        }
      }
    } finally {
      setLoading(false);
    }
  }, [makeRequest, token]);

  return (
    <ClientContext.Provider value={{ loading, branding }}>
      {children}
    </ClientContext.Provider>
  );
};
