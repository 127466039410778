import { Ticket } from '@hpx-it/queue-client';
import { useWindowDimensions } from '@hpx-it/react-app';
import { Box, Divider, Typography } from '@mui/material';
import { QueueContext } from 'contexts';
import { useContext } from 'react';
import { TermsScreen } from 'screens/TermsScreen';

import { AddTicketTimeslotCard } from './AddTicketTimeslotCard';
import { CompletedTicketCard } from './CompletedTicketCard';
import {
  getDividerStyle,
  getReferenceStyle,
  getScreenTitleStyle,
  getTicketScreenBoxStyle,
} from './style';
import { TicketCard } from './TicketCard';
import { TicketScreenFooter } from './TicketScreenFooter';

export const TicketScreen = ({
  ticket,
  reference,
}: {
  ticket: Ticket;
  reference?: string;
}) => {
  const { setTicket } = useContext(QueueContext);

  const isTicketCompleted = ticket.isCompleted() && !ticket.isLive();

  if (!ticket.hasSmsConsent() || !ticket.hasVideoConsent()) {
    return <TermsScreen />;
  }

  return (
    <Box {...getTicketScreenBoxStyle()}>
      <Typography {...getScreenTitleStyle()}>
        Remote Assist Video Appointment
      </Typography>
      {reference && (
        <Typography {...getReferenceStyle()}>ID: {reference}</Typography>
      )}

      <Divider {...getDividerStyle()} />
      <AddTicketAndTicketInfo
        ticket={ticket}
        setTicket={setTicket}
        isTicketCompleted={isTicketCompleted}
      />
      <TicketScreenFooter isTicketCompleted={isTicketCompleted} />
    </Box>
  );
};

const AddTicketAndTicketInfo = ({
  ticket,
  setTicket,
  isTicketCompleted,
}: {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  isTicketCompleted: boolean;
}) => {
  if (isTicketCompleted) {
    return <CompletedTicketCard />;
  }

  return (
    <>
      {!ticket.hasTimeslot() ? (
        <AddTicketTimeslotCard ticket={ticket} setTicket={setTicket} />
      ) : (
        <TicketCard ticket={ticket} setTicket={setTicket} />
      )}
    </>
  );
};
