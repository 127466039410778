import { Box, Button, Divider, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  orientation: 'horizontal',
  sx: {
    my: 2,
  },
});

export const getFooterSentenceStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  fontSize: '0.75rem',
  mb: 2,
});

export const getCancelHereStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  display: 'inline',
  sx: {
    textDecoration: 'underline',
    fontSize: '0.75rem',
    color: 'darkBlue',
  },
});

export const getDialogBoxStyle = (): ComponentProps<typeof Box> => ({
  p: 1.5,
});

export const getDialogExitStyle = (): ComponentProps<typeof Button> => ({
  variant: 'outlined',
  color: 'primary',
  sx: {
    textTransform: 'none',
    mr: 1,
  },
});

export const getDialogConfirmStyle = (): ComponentProps<typeof Button> => ({
  variant: 'contained',
  color: 'primary',
  sx: {
    textTransform: 'none',
  },
});
