import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

import {
  getCalendarBoxStyle,
  getCalendarDialogStyle,
  getCalendarDialogTitleStyle,
  getCalendarStyle,
} from './style';

type CalendarDialogProps = {
  calendarOpen: boolean;
  setCalendarOpen: (calendarOpen: boolean) => void;
  selectedDate: DateTime;
  shouldDisableDate: (date: DateTime) => boolean;
  onCalendarSelect: (date: DateTime) => void;
};

export const CalendarDialog = ({
  calendarOpen,
  setCalendarOpen,
  selectedDate,
  shouldDisableDate,
  onCalendarSelect,
}: CalendarDialogProps) => {
  return (
    <Dialog
      {...getCalendarDialogStyle({
        open: calendarOpen,
        onClose: () => setCalendarOpen(false),
      })}
    >
      <DialogTitle {...getCalendarDialogTitleStyle()}>Select Date</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Box {...getCalendarBoxStyle()}>
            <DateCalendar
              {...getCalendarStyle({
                selectedDate: selectedDate,
                shouldDisableDate: shouldDisableDate,
                onChange: onCalendarSelect,
              })}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};
