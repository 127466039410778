import { Ticket } from '@hpx-it/queue-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useLoadingButton } from 'hooks';
import { Dispatch, SetStateAction } from 'react';

import {
  getDialogBoxStyle,
  getDialogConfirmStyle,
  getDialogExitStyle,
} from './style';

type ExtendDialogProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const ExtendDialog = ({
  ticket,
  setTicket,
  open,
  setOpen,
}: ExtendDialogProps) => {
  const loadingButtonProps = useLoadingButton(async () => {
    setTicket(await ticket.on('EXTEND'));
    setOpen(false);
  });

  const handleExtendDialogClose = async () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleExtendDialogClose}>
      <Box {...getDialogBoxStyle()}>
        <DialogTitle>Extend</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will extend the end of your timeslot by 1 hour.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExtendDialogClose} {...getDialogExitStyle()}>
            Exit
          </Button>
          <Button {...getDialogConfirmStyle()} {...loadingButtonProps}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
