import 'reflect-metadata';

import './setup';

import { Theme, ThemeProvider } from '@mui/material';
import {
  DeveloperApiClientProvider,
  MakeRequestProvider,
  QueueProvider,
  RemoteAssistProvider,
} from 'contexts';
import { ClientProvider } from 'contexts/clientContext';
import { Main } from 'main/Main';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { generateTheme } from 'theme';

function App() {
  const [theme, setTheme] = useState<Theme>(generateTheme());

  return (
    <>
      <ThemeProvider theme={theme}>
        <MakeRequestProvider>
          <DeveloperApiClientProvider>
            <ClientProvider setTheme={setTheme}>
              <RemoteAssistProvider>
                <QueueProvider>
                  <Main />
                </QueueProvider>
              </RemoteAssistProvider>
            </ClientProvider>
          </DeveloperApiClientProvider>
        </MakeRequestProvider>
      </ThemeProvider>
      <Toaster />
    </>
  );
}

export default App;
