import { Ticket } from '@hpx-it/queue-client';
import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';

import { getTimeslotTitleStyle } from '../style';
import { JoinQueueDialog } from './JoinQueueDialog';
import {
  getDirectCallExplanationStyle,
  getGridContainerStyle,
  getSubmitButtonStyle,
} from './style';

type JoinQueueFormProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const JoinQueueForm = ({ ticket, setTicket }: JoinQueueFormProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Typography {...getTimeslotTitleStyle()}>Join Queue Now</Typography>
      <Grid container {...getGridContainerStyle()}>
        <Grid item>
          <Typography {...getDirectCallExplanationStyle()}>
            Join the queue now and we'll send you a video call link as soon as
            we can.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            {...getSubmitButtonStyle()}
            onClick={() => setDialogOpen(true)}
          >
            Join Now
          </Button>
        </Grid>
      </Grid>
      <JoinQueueDialog
        ticket={ticket}
        setTicket={setTicket}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </>
  );
};
