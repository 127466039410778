import { Box, Button, Divider } from '@mui/material';
import { ComponentProps } from 'react';

export const getButtonBoxStyle = (): ComponentProps<typeof Box> => ({
  alignItems: 'center',
  sx: {
    marginY: '0.5rem',
  },
});

export const getAllowedTimesButtonStyle = ({
  onClick,
}: {
  onClick: () => void;
}): ComponentProps<typeof Button> => ({
  sx: {
    height: '100%',
    width: '60%',
    fontSize: '0.9rem',
    whiteSpace: 'pre-line',
    textTransform: 'none',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  },
  onClick,
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  sx: {
    width: '60%',
    padding: 0,
    marginX: 'auto',
  },
});
