import { Ticket } from '@hpx-it/queue-client';
import { Box, Chip, Grid, Typography } from '@mui/material';

import { formatTimeslotDate, formatTimeslotTime } from './dateFormatter';
import { StatusChip } from './StatusChip';
import {
  getTimeRangeBoxStyle,
  getTimeslotDateStyle,
  getTimeslotTimeStyle,
  getTimeslotTitleStyle,
} from './style';

type TimeslotInfoProps = {
  ticket: Ticket;
};

export const TimeslotInfo = ({ ticket }: TimeslotInfoProps) => {
  const [timeslotStart, timeslotEnd, postponedUntil] = [
    ticket.getTimeslotStart(),
    ticket.getTimeslotEnd(),
    ticket.getPostponedUntil(),
  ];
  const [timeslotStartDate, timeslotEndDate, postponedUntilDate] = [
    timeslotStart ? new Date(timeslotStart) : undefined,
    timeslotEnd ? new Date(timeslotEnd) : undefined,
    postponedUntil ? new Date(postponedUntil) : undefined,
  ];

  return (
    <>
      <Typography {...getTimeslotTitleStyle()}>Timeslot Details</Typography>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        py={1}
      >
        <Grid item>
          <Typography>Status: </Typography>
        </Grid>
        <Grid item>
          <StatusChip ticket={ticket} />
        </Grid>
      </Grid>

      {timeslotStartDate && (
        <Typography {...getTimeslotDateStyle()}>
          {formatTimeslotDate(timeslotStartDate)}
        </Typography>
      )}

      {(timeslotStartDate || timeslotEndDate) && (
        <Box {...getTimeRangeBoxStyle()}>
          {timeslotStartDate && (
            <Typography {...getTimeslotTimeStyle()}>
              {formatTimeslotTime(timeslotStartDate)}
            </Typography>
          )}
          {timeslotEndDate && (
            <Typography {...getTimeslotTimeStyle()}>
              &nbsp;—&nbsp;{formatTimeslotTime(timeslotEndDate)}
            </Typography>
          )}
        </Box>
      )}

      {ticket.is('POSTPONED') && postponedUntilDate && (
        <Box>
          <Typography {...getTimeslotTimeStyle()}>
            Postponed until: {formatTimeslotTime(postponedUntilDate)}
          </Typography>
        </Box>
      )}
    </>
  );
};
