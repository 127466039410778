import { queue } from '@hpx-it/queue-client';
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { getTimeslotDialogStyle, getTimeslotDialogTitleStyle } from './style';
import { TimeslotDialogList } from './TimeslotDialogList/TimeslotDialogList';

type TimeslotDialogProps = {
  timeSelectOpen: boolean;
  setTimeSelectOpen: (open: boolean) => void;
  allowedTimes: queue.AvailableTimeslot[];
  setSelectedTimeslot: (timeslot: queue.AvailableTimeslot) => void;
  formatTimeslot: (timeslot: queue.AvailableTimeslot) => string;
};

export const TimeslotDialog = ({
  timeSelectOpen,
  setTimeSelectOpen,
  allowedTimes,
  setSelectedTimeslot,
  formatTimeslot,
}: TimeslotDialogProps) => {
  return (
    <Dialog
      {...getTimeslotDialogStyle({
        open: timeSelectOpen,
        onClose: () => setTimeSelectOpen(false),
      })}
    >
      <DialogTitle {...getTimeslotDialogTitleStyle()}>Select Time</DialogTitle>
      <TimeslotDialogList
        setTimeSelectOpen={setTimeSelectOpen}
        setSelectedTimeslot={setSelectedTimeslot}
        allowedTimes={allowedTimes}
        formatTimeslot={formatTimeslot}
      />
    </Dialog>
  );
};
