import { Divider, Paper, Typography } from '@mui/material';

import {
  getCompletedDescriptionStyle,
  getCompletedTitleStyle,
  getDividerStyle,
  getTicketPaperStyle,
} from './style';

export const CompletedTicketCard = () => {
  return (
    <Paper {...getTicketPaperStyle()}>
      <Typography {...getCompletedTitleStyle()}>Work Order Reviewed</Typography>
      <Divider {...getDividerStyle()} />
      <Typography {...getCompletedDescriptionStyle()}>
        A technician will reach out to schedule a visit if needed to resolve
        your issue(s).
      </Typography>
    </Paper>
  );
};
