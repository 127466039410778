import { CircularProgress, Grid, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const LOADING_BOX_ID = 'loading-box';
export const LOADING_CIRCULAR_PROGRESS_ID = 'loading-circular-progress';
export const LOADING_TEXT_ID = 'loading-text';

export const getLoadingBoxStyle: (
  windowHeight: number,
) => ComponentProps<typeof Grid> = (windowHeight) => ({
  'data-testId': LOADING_BOX_ID,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: `${windowHeight - 50}px`, // subtracting the height of the TopBar, adjust accordingly
});

export const getCircularProgressStyle: () => ComponentProps<
  typeof CircularProgress
> & { 'data-testId': string } = () => ({
  'data-testId': LOADING_CIRCULAR_PROGRESS_ID,
  mb: '1.5rem',
  sx: {
    color: (theme) => theme.palette.grey[800],
  },
});

export const getLoadingTextStyle: () => ComponentProps<
  typeof Typography
> = () => ({
  'data-testId': LOADING_TEXT_ID,
  variant: 'h6',
  fontFamily: 'Roboto',
  marginTop: '1rem',
  sx: {
    color: (theme) => theme.palette.grey[800],
  },
});
