import { Ticket, queue } from '@hpx-it/queue-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useLoadingButton } from 'hooks';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction } from 'react';

import { getFormattedDate, getFormattedTime } from '../format';
import {
  getDialogBoxStyle,
  getDialogConfirmStyle,
  getDialogExitStyle,
} from './style';

type SubmitTimeslotDialogProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedTimeslot: queue.AvailableTimeslot;
};

export const SubmitTimeslotDialog = ({
  ticket,
  setTicket,
  open,
  setOpen,
  selectedTimeslot,
}: SubmitTimeslotDialogProps) => {
  const loadingButtonProps = useLoadingButton(async () => {
    if (selectedTimeslot !== undefined) {
      setTicket(
        await ticket.schedule({
          start: selectedTimeslot.start,
          end: selectedTimeslot.end,
        }),
      );
      setOpen(false);
    }
  });

  const handleSubmitTimeslotDialogClose = async () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleSubmitTimeslotDialogClose}>
      <Box {...getDialogBoxStyle()}>
        <DialogTitle>Submit Timeslot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you will be home and available for a video call
            within the timeslot below.
          </DialogContentText>
          <br />
          <DialogContentText>
            {`${getFormattedDate(selectedTimeslot.start)}: ${getFormattedTime(
              DateTime.fromJSDate(selectedTimeslot.start),
            )} — ${getFormattedTime(
              DateTime.fromJSDate(selectedTimeslot.end),
            )}`}
          </DialogContentText>
          <br />
          <DialogContentText>
            We'll send you a text message with a link to join your video call
            when it's about to start.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmitTimeslotDialogClose}
            {...getDialogExitStyle()}
          >
            Exit
          </Button>
          <Button {...getDialogConfirmStyle()} {...loadingButtonProps}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
