import { Ticket } from '@hpx-it/queue-client';
import { Button } from '@mui/material';
import { useState } from 'react';

import { ExtendDialog } from './ExtendDialog';
import { getExtendButtonStyle } from './style';

type ExtendButtonProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const ExtendButton = ({ ticket, setTicket }: ExtendButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button {...getExtendButtonStyle()} onClick={() => setDialogOpen(true)}>
        Extend my timeslot by 1 hour
      </Button>
      <ExtendDialog
        ticket={ticket}
        setTicket={setTicket}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </>
  );
};
