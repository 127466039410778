import { Theme, createTheme } from '@mui/material';

const DEFAULT_PRIMARY_COLOR = '#21998d';

export const generateTheme = (primaryColor = DEFAULT_PRIMARY_COLOR): Theme => {
  return createTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: {
        main: primaryColor === DEFAULT_PRIMARY_COLOR ? '#DE1B7A' : '#000000',
      },
      error: { main: '#f44336' },
      warning: { main: '#ffa726' },
      success: { main: '#66bb6a' },
    },
  });
};
