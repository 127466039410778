import { Box, List, ListItem, ListItemButton } from '@mui/material';
import { ComponentProps } from 'react';

export const getTimeslotDialogListBoxStyle = (): ComponentProps<
  typeof Box
> => ({
  sx: {
    textAlign: 'center', // Center text within Box
  },
});

export const getTimeslotDialogListStyle = (): ComponentProps<typeof List> => ({
  sx: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 0,
  },
});

export const getTimeslotDialogListItemStyle = (): ComponentProps<
  typeof ListItem
> => ({
  sx: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

export const getTimeslotDialogListButtonStyle = ({
  onClick,
}: {
  onClick: () => void;
}): ComponentProps<typeof ListItemButton> => ({
  sx: {
    borderRadius: '8px',
    width: '100%',
    textAlign: 'center',
  },
  onClick,
});
