import { Ticket } from '@hpx-it/queue-client';
import { useState } from 'react';

export const useTicket = (
  ticket?: Ticket,
): [Ticket | undefined, (ticket: Ticket | undefined) => void] => {
  const [selectedTicket, _setSelectedTicket] = useState<Ticket | undefined>(
    ticket,
  );

  function setSelectedTicket(ticket: Ticket | undefined) {
    _setSelectedTicket(ticket?.clone());
  }
  return [selectedTicket, setSelectedTicket];
};
