import { Divider, Paper, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getTicketPaperStyle = (): ComponentProps<typeof Paper> => ({
  elevation: 4,
  sx: {
    textAlign: 'center',
    my: 3,
    p: 3,
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0.5rem',
    opacity: 1,
    pointerEvents: 'initial',
  },
});

export const getCompletedTitleStyle = (): ComponentProps<
  typeof Typography
> => ({
  fontWeight: 500,
  mb: 1,
});

export const getCompletedDescriptionStyle = (): ComponentProps<
  typeof Typography
> => ({
  mb: 0.5,
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  orientation: 'horizontal',
  sx: {
    my: 2,
  },
});
