import { Grid } from '@mui/material';
import { Header } from 'components';
import {
  ClientContext,
  MakeRequestContext,
  QueueContext,
  RemoteAssistContext,
} from 'contexts';
import { useContext } from 'react';
import {
  ErrorScreen,
  InvalidStateScreen,
  LoadingScreen,
  NotFoundScreen,
  TicketScreen,
  VideoRoomScreen,
} from 'screens';
import { TermsScreen } from 'screens/TermsScreen';

import { getMainContainerStyle } from './style';

export function Main() {
  const { loading: clientLoading } = useContext(ClientContext);
  const { remoteAssist, termsAccepted } = useContext(RemoteAssistContext);
  const { token } = useContext(MakeRequestContext);
  const { ticket, loadingTickets, routeToVideoRoom } = useContext(QueueContext);

  if (!token)
    <ScreenWrapper>
      <ErrorScreen />
    </ScreenWrapper>;

  if (clientLoading || loadingTickets) {
    return (
      <ScreenWrapper>
        <LoadingScreen />
      </ScreenWrapper>
    );
  }

  if (!remoteAssist) {
    return (
      <ScreenWrapper>
        <NotFoundScreen />
      </ScreenWrapper>
    );
  }

  if (
    remoteAssist.status === 'completed' ||
    remoteAssist.status === 'canceled'
  ) {
    return (
      <ScreenWrapper>
        <InvalidStateScreen />
      </ScreenWrapper>
    );
  }

  if (
    ticket &&
    remoteAssist.schedule.type === 'scheduled' &&
    !('start_time' in remoteAssist.schedule) &&
    !routeToVideoRoom
  ) {
    return (
      <ScreenWrapper>
        <TicketScreen ticket={ticket} reference={remoteAssist.reference} />
      </ScreenWrapper>
    );
  }

  if (!termsAccepted) {
    return (
      <ScreenWrapper>
        <TermsScreen />
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper>
      <VideoRoomScreen />
    </ScreenWrapper>
  );
}

const ScreenWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid {...getMainContainerStyle()}>
      <Header />
      {children}
    </Grid>
  );
};
