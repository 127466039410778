import { Box } from '@mui/material';
import { ClientContext } from 'contexts/clientContext';
import { useContext } from 'react';

import {
  getHeaderBoxStyle,
  getImageContainerBoxStyle,
  getImageStyle,
} from './style';

export const Header = () => {
  const { loading, branding } = useContext(ClientContext);

  return (
    <Box {...getHeaderBoxStyle()}>
      {!loading && (
        <Box {...getImageContainerBoxStyle()}>
          <img
            {...getImageStyle()}
            alt="Company Logo"
            src={branding.logoURL ?? '/favicon.ico'}
          />
        </Box>
      )}
    </Box>
  );
};
