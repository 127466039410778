import { Ticket } from '@hpx-it/queue-client';
import { Chip, ChipProps } from '@mui/material';

const getChipProps = (ticket: Ticket): ChipProps => {
  if (ticket.hasTimeslotEnded()) {
    return {
      color: 'error',
      label: 'Expired',
    };
  }

  if (ticket.isClaimed()) {
    return {
      color: 'success',
      label: 'Front of Queue',
    };
  }

  if (!ticket.hasTimeslotStarted()) {
    return {
      color: 'primary',
      label: 'Awaiting Timeslot',
    };
  }

  if (ticket.isResidentUnavailable()) {
    return {
      color: 'error',
      label: 'Temporarily Unavailable',
    };
  }

  if (!ticket.isAcknowledged()) {
    return {
      color: 'error',
      label: 'Needs Confirmation',
    };
  }

  return {
    color: 'success',
    label: 'In Queue',
  };
};

export const StatusChip = ({ ticket }: { ticket: Ticket }) => {
  return <Chip size="small" {...getChipProps(ticket)} />;
};
