import { Divider } from '@mui/material';

import { getDividerStyle } from '../style';
import { CancelRemoteAssist } from './CancelRemoteAssist';
import { HaveANiceDay } from './HaveANiceDay';

export const TicketScreenFooter = ({
  isTicketCompleted,
}: {
  isTicketCompleted: boolean;
}) => {
  return (
    <>
      <Divider {...getDividerStyle()} />
      {!isTicketCompleted ? <CancelRemoteAssist /> : <HaveANiceDay />}
    </>
  );
};
