import { Ticket } from '@hpx-it/queue-client';
import { Paper } from '@mui/material';

import { getTicketPaperStyle } from './style';
import { TicketActions } from './TicketActions';
import { TimeslotInfo } from './TimeslotInfo';

type TicketCardProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
};

export const TicketCard = ({ ticket, setTicket }: TicketCardProps) => {
  return (
    <Paper {...getTicketPaperStyle()}>
      <TimeslotInfo ticket={ticket} />
      <TicketActions ticket={ticket} setTicket={setTicket} />
    </Paper>
  );
};
