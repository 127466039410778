import { Grid } from '@mui/material';
import { ComponentProps } from 'react';

export const getMainContainerStyle: () => ComponentProps<typeof Grid> = () => ({
  container: true,
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});
