import {
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  Theme,
  lighten,
} from '@mui/material';
import { ComponentProps } from 'react';

const selectedBgColour = (theme: Theme) =>
  lighten(theme.palette.primary.main, 0.8);

export const getAvailableTimeslotButtonStyle = ({
  selected,
}: {
  selected: boolean;
}): ComponentProps<typeof Button> => ({
  variant: 'outlined',
  disableTouchRipple: true,
  sx: {
    textTransform: 'none',
    ...(selected && {
      bgcolor: (theme) => selectedBgColour(theme),
    }),
    ':hover': {
      bgcolor: (theme) => (selected ? selectedBgColour(theme) : 'white'),
    },
  },
});

export const getButtonBoxStyle = (): ComponentProps<typeof Box> => ({
  sx: { display: 'flex', justifyContent: 'center', width: '100%' },
});

export const getSubmitButtonStyle = ({
  disabled,
}: {
  disabled: boolean;
}): ComponentProps<typeof Button> => ({
  variant: 'contained',
  disabled,
  sx: {
    textTransform: 'none',
    width: '50%',
    marginTop: '0.75rem',
  },
});

export const getDialogBoxStyle = (): ComponentProps<typeof Box> => ({
  p: 1.5,
});

export const getDialogExitStyle = (): ComponentProps<typeof Button> => ({
  variant: 'outlined',
  color: 'primary',
  sx: {
    textTransform: 'none',
    ml: 'auto',
  },
});

export const getDialogConfirmStyle = (): ComponentProps<typeof Button> => ({
  variant: 'contained',
  color: 'primary',
  sx: {
    textTransform: 'none',
  },
});

export const getTimeslotActionsGridContainerStyle = (): ComponentProps<
  typeof Grid
> => ({
  container: true,
  item: true,
  justifyContent: 'center',
  alignItems: 'center',
  direction: 'column',
  spacing: 1,
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  orientation: 'horizontal',
  sx: {
    my: 2,
  },
});

export const getPaginationStyle = (): ComponentProps<typeof Pagination> => ({
  siblingCount: 0,
  size: 'small',
  color: 'primary',
});
