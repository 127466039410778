import { Box, Button } from '@mui/material';
import { ComponentProps } from 'react';

export const getUnavailableButtonStyle = (): ComponentProps<typeof Button> => ({
  variant: 'outlined',
  sx: {
    textTransform: 'none',
    fontWeight: 500,
  },
});
export const getDialogBoxStyle = (): ComponentProps<typeof Box> => ({
  p: 1.5,
});

export const getDialogExitStyle = (): ComponentProps<typeof Button> => ({
  variant: 'outlined',
  color: 'primary',
  sx: {
    textTransform: 'none',
    mr: 1,
  },
});

export const getDialogConfirmStyle = (): ComponentProps<typeof Button> => ({
  variant: 'contained',
  color: 'primary',
  sx: {
    textTransform: 'none',
  },
});
