import { Divider, Grid } from '@mui/material';
import { ComponentProps } from 'react';

export const getTicketActionsGridStyle = (): ComponentProps<typeof Grid> => ({
  spacing: 2,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'left',
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  orientation: 'horizontal',
  sx: {
    my: 2,
  },
});
