import { Box, ButtonProps, Container, Typography } from '@mui/material';
import { StackProps } from '@mui/material/Stack';
import { TypographyProps } from '@mui/material/Typography';
import { ComponentProps } from 'react';
export const termsAndConditionsBoxStyling = (): ComponentProps<typeof Box> => ({
  sx: {
    overflowY: 'auto',
    marginBottom: 'auto',
    padding: 2,
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const termsAndConditionsContainerStyling = (): ComponentProps<
  typeof Container
> => ({
  sx: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const termsAndConditionsTitleStyling: TypographyProps = {
  variant: 'h4',
  component: 'h2',
  textAlign: 'center',
};

export const termsAndConditionsTextStyling: TypographyProps = {
  variant: 'body1',
  component: 'h2',
  textAlign: 'center',
};

export const termsAndConnditionsListStyling: TypographyProps = {
  variant: 'body1',
  component: 'div',
  textAlign: 'center',
};

export const termsAndConditionsStackStyling: StackProps = {
  direction: 'column',
  spacing: 1,
  justifyContent: 'center',
  alignItems: 'center',
  sx: {
    position: 'sticky',
    bottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: 'background.paper',
  },
};

export const termsAndConditionsButtonStyling: ButtonProps = {
  sx: { width: '30%' },
  variant: 'contained',
  color: 'primary',
};
