import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getTicketPaperStyle = (): ComponentProps<typeof Paper> => ({
  elevation: 4,
  sx: {
    textAlign: 'center',
    my: 3,
    p: 3,
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0.5rem',
    opacity: 1,
    pointerEvents: 'initial',
  },
});

export const getTimeslotTitleStyle = (): ComponentProps<typeof Typography> => ({
  fontWeight: 500,
  mb: 0.5,
});

export const getTimeslotExplanationStyle = (): ComponentProps<
  typeof Typography
> => ({
  color: 'grey',
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  orientation: 'horizontal',
  sx: {
    my: 3,
  },
});

export const getDividerTextStyle = (): ComponentProps<typeof Typography> => ({
  fontStyle: 'italic',
  color: 'grey',
});

export const getAvailableTimeslotsGridStyle = (): ComponentProps<
  typeof Grid
> => ({
  container: true,
  justifyContent: 'center',
  flexDirection: 'column',
  mt: 1,
  spacing: 1,
});
