import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { RemoteAssistContext } from 'contexts';
import { useLoadingButton } from 'hooks';
import { Dispatch, SetStateAction, useContext } from 'react';

import {
  getDialogBoxStyle,
  getDialogConfirmStyle,
  getDialogExitStyle,
} from './style';

type CancelRemoteAssistDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const CancelRemoteAssistDialog = ({
  open,
  setOpen,
}: CancelRemoteAssistDialogProps) => {
  const { getRemoteAssist, cancelRemoteAssist } =
    useContext(RemoteAssistContext);

  const loadingButtonProps = useLoadingButton(async () => {
    await cancelRemoteAssist();
    setOpen(false);
    await getRemoteAssist();
  });

  const handleCancelRemoteAssistDialogClose = async () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelRemoteAssistDialogClose}>
      <Box {...getDialogBoxStyle()}>
        <DialogTitle>Cancel Remote Assist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your video appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelRemoteAssistDialogClose}
            {...getDialogExitStyle()}
          >
            Exit
          </Button>
          <Button {...getDialogConfirmStyle()} {...loadingButtonProps}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
