import { Dialog, DialogTitle } from '@mui/material';
import { ComponentProps } from 'react';

export const getTimeslotDialogStyle = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ComponentProps<typeof Dialog> => ({
  open,
  onClose,
  fullWidth: true,
  maxWidth: 'sm',
  PaperProps: {
    sx: {
      borderRadius: '12px',
      boxShadow: 3,
    },
  },
});

export const getTimeslotDialogTitleStyle = (): ComponentProps<
  typeof DialogTitle
> => ({
  sx: {
    fontSize: '1.25rem',
    fontWeight: 600,
    paddingBottom: '0.5rem',
    alignItems: 'center',
  },
});
