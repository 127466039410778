import { queue } from '@hpx-it/queue-client';
import { Box, Button } from '@mui/material';

import { getAllowedTimesButtonStyle, getButtonBoxStyle } from './style';

type TimeButtonProps = {
  setTimeSelectOpen: (open: boolean) => void;
  selectedTimeslot: queue.AvailableTimeslot | undefined;
  formatTimeslot: (timeslot: queue.AvailableTimeslot) => string;
};

export const TimeButton = ({
  setTimeSelectOpen,
  selectedTimeslot,
  formatTimeslot,
}: TimeButtonProps) => {
  const buttonDisabled = selectedTimeslot === undefined;

  return (
    <Box {...getButtonBoxStyle()}>
      <Button
        variant="outlined"
        color="primary"
        disabled={buttonDisabled}
        {...getAllowedTimesButtonStyle({
          onClick: () => {
            setTimeSelectOpen(true);
          },
        })}
      >
        {!buttonDisabled
          ? formatTimeslot(selectedTimeslot)
          : 'No timeslots for this date'}
      </Button>
    </Box>
  );
};
